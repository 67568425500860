var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "main-wrap" }, [
        _c("div", { staticClass: "right-aside-container" }, [
          _c(
            "div",
            { staticClass: "right-aside-wrap" },
            [
              _c("RecommendListComponent", {
                attrs: { "recommend-list": _vm.recommendList },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "left-aside-container" }, [
          _c(
            "div",
            { staticClass: "left-aside-wrap" },
            [
              _vm.articleList && _vm.articleList.length > 0
                ? _c(
                    "div",
                    { staticClass: "article-list" },
                    _vm._l(_vm.articleList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          on: {
                            click: function ($event) {
                              return _vm.linkDetail(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "article-list-item" }, [
                            _c(
                              "div",
                              { staticClass: "left" },
                              [
                                _c("el-image", {
                                  staticClass: "image",
                                  attrs: {
                                    src: item.coverUrl,
                                    alt: item.title,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "right" }, [
                              _c("h1", { staticClass: "title" }, [
                                _c("a", {
                                  attrs: { href: item.link },
                                  domProps: { textContent: _vm._s(item.title) },
                                }),
                              ]),
                              _c("p", {
                                staticClass: "content",
                                domProps: { textContent: _vm._s(item.brief) },
                              }),
                              _c("div", [
                                _c("i", { staticClass: "tag" }, [
                                  _vm._v(_vm._s(item.categoryName)),
                                ]),
                                _c("span", { staticClass: "date" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        new Date(item.gmtCreate),
                                        "yyyy-MM-dd hh:mm"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _c("div", { staticStyle: { clear: "both" } }),
                            ]),
                          ]),
                          _c("el-divider"),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("el-empty", { attrs: { "image-size": 200 } }),
              _c("FriendLinks"),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }